<template>
  <div class="userDaily">
    <div class="userDaily_tab">
      <div @click="tabFun(1)" :class="type == 1 ? 'active' : ''">所有作品</div>
      <div @click="tabFun(2)" :class="type == 2 ? 'active' : ''">上榜作品</div>
    </div>
    <div class="list">
      <div class="ranking_ul clearfix" v-if="list.length > 0">
        <!-- wow animate__animated animate__fadeInUp -->
        <div
          class="fl daily_li"
          :style="{ animationDelay: 0.1 * index + 's' }"
          v-for="(item, index) in list"
          :key="index"
          @mouseover="show(item.id)"
          @mouseleave="hide"
           @click.stop="toDetail(item.id)"
        >
          <!-- @mouseleave="hide" -->
          <div @click.stop="toDetail(item.id)">
            <div class="ranking_li_box">
              <img v-lazy="item.thumb" alt="" />
            </div>
            <div class="ranking_li_info_one">
                <div class="ranking_li_info_one_name">{{ item.title }}</div>
                <div class="ranking_li_upvote" :class="item.is_goods == 1? 'in':''" @click.stop="goodsFun(item.id,index)">
                  <img v-if="item.is_goods == 1" src="../../assets/images/dianzan.png" alt="">
                  <img v-else src="../../assets/images/dianzanno.png" alt="">
                  {{ item.goods>10000?'1w+':item.goods}}
                </div>
              </div>
            <div class="clearfix">
              <div class="fl dailyWord_li_l">
                <img class="fl" :src="item.user.thumb" alt="" />
                <span>{{ item.user.username }}</span>
              </div>
              <div class="fr dailyWord_li_r">{{ item.last_time }}</div>
            </div>
          </div>
          <div v-if="show_id == item.id" class="floats">
            <div class="show_hide">
           
              <el-select v-model="item.public" placeholder="请选择" :popper-append-to-body="false" @change="change(item.id, item.public)">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  
                >
                </el-option>
              </el-select>
            </div>
            <div class="deletes" @click.stop="deletes(item.id)">删除</div>
          </div>
        </div> 
      </div>
      <el-empty v-else description="暂无数据"></el-empty>
      <page
        v-if="total > 12"
        :page="page"
        :limit="limit"
        :total="total"
        ref="page"
      ></page>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <dayword :id="id" :key="id" @parentUserFun="parentUserFun" @isLoginFun="isLoginFun" @toggleId="toggleId"></dayword>
    </el-dialog>
  </div>
</template>

<script>
// import { WOW } from 'wowjs'
import page from "../../components/page.vue";
import dayword from '../../components/dailyDetail.vue'
export default {
  name: "userDaily",
  components: {
    page,
    dayword
  },
  data() {
    return {
      material_total: 0, //作品总数
      list: [],

      // 分页
      total: 0, //总数
      page: 1, //页数
      limit: 12, //每页个数
      userId: this.$route.query.userId,
      type: 1, //类型
      is_benren: 1,
      active: 1,
      inactive: 0,
      options: [
        {
          value: 1,
          label: "公开",
        },
        {
          value: 0,
          label: "私密",
        },
      ],
      value: "",
      show_id: 0,

      value1: false,

      dialogVisible: false,
      id:'',
    };
  },
  mounted() {
    this.$parent.tab = 2;
    this.$parent.tabIndex = 1;
    this.is_benren = this.$parent.is_benren;
    this.userId = this.$route.query.userId;
    this.getUser(this.userId);
    (this.userId = this.$route.query.userId),
      // new WOW().init();
      (this.$parent.$parent.routerIndex = 0);
    this.$parent.$parent.showIndex = 1;
    if (localStorage.getItem("clearAll") != 2) {
      this.page = 1;
      if (this.$refs.page) {
        this.$refs.page.currentPage = 1;
      }
      this.listFun();
    }
    localStorage.removeItem("clearAll");
  },
  methods: {
    getUser(id) {
      var that = this;
      this.$api.POST(
        this.$face.memberInfo,
        {
          user_id: id || "",
        },
        function (res) {
          that.is_benren = res.data.is_benren;
        }
      );
    },
    listFun() {
      var that = this;
      this.$api.POST(
        this.type == 1 ? this.$face.memberMyworks : this.$face.memberListworks,
        {
          user_id: this.userId,
          page: this.page,
          limit: this.limit,
        },
        function (res) {
          that.list = res.data.lists;

          that.total = res.data.total;
          for (let i in that.list) {
            if (that.list[i].user.thumb.indexOf("http") == -1) {
              that.list[i].user.thumb = require("../../assets/images/head.png");
            }
          }
        }
      );
    },
    show(id) {
      if (this.is_benren == 1) {
        this.show_id = id;
      }
    },
    hide() {
      this.show_id = 0;
    },
    change(id, pub) {
      this.$api.POST(
        pub == 0 ? this.$face.MyworksHide : this.$face.MyworksOpen,
        {
          id: id,
        },
        function (res) {}
      );
    },
    deletes(id) {
      this.$confirm("此操作将删除该信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$api.POST(this.$face.MyworksDelete, { id: id }, (res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.listFun();
          }
        });
      });
    },
    tabFun(el) {
      this.type = el;
      this.page = 1;
      this.listFun();
    },

    toDetail(id) {
      //this.$router.push({ name: "dailyWordDetail", query: { id: id } });
      // if(this.type == 1){
      //   this.dialogVisible = true;
      //   this.id = id;
      // }else{
      //   let routeUrl = this.$router.resolve({
      //       name: "dailyWordDetail",
      //       query: { id: id },
      //   });
      //   window.open(routeUrl.href, "_blank");
      // }
      this.dialogVisible = true;
      this.id = id;
      
    },
    parentUserFun(id){
      this.$parent.$parent.getUser(id)
    },
    isLoginFun(){
      this.dialogVisible = false
      this.$parent.$parent.loginShow();
    },
    toggleId(id){
      this.id = id;
    },
    // 点赞
    goodsFun(id,i){
        var that = this;
        var params ={
            task_id: id,
        }
        this.$api.POST(this.$face.fabulous_good_font_task,params,function(res){
            that.$utile.prompt('success',res.msg);
            if(that.list[i].is_goods == 1){
              that.list[i].is_goods = 0
              that.list[i].goods--
            }else{
              that.list[i].is_goods = 1
              that.list[i].goods++
            }
            that.$forceUpdate()
        })
    },
  },
  activated() {
    this.$nextTick(() => {
      this.$parent.tab = 2;
      this.$parent.tabIndex = 1;
      this.is_benren = this.$parent.userId;
      // new WOW().init();
      this.$parent.$parent.routerIndex = 0;
      this.$parent.$parent.showIndex = 1;
      if (localStorage.getItem("clearAll") != 2) {
        this.page = 1;
        if (this.$refs.page) {
          this.$refs.page.currentPage = 1;
        }
      }
      this.listFun();
      localStorage.removeItem("clearAll");
    });
  },
};
</script>

<style scoped>
.userDaily {
  padding-top: 48px;
}
.userDaily_tab {
  margin-bottom: 28px;
  display: flex;
  align-items: center;
}
.userDaily_tab div {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 10px;
  font-size: 15px;
  color: #999999;
  margin-right: 33px;
  cursor: pointer;
}
.userDaily_tab div.active {
  background-color: #5957ff;
  color: #ffffff;
}
.userDaily_tab div:hover {
  background-color: #ebebff;
  color: #5957ff;
}

.list {
  padding-bottom: 59px;
  min-height: 300px;
}
.ranking_ul {
  margin: 0 -13.33px 26px;
}
.daily_li {
  width: 330px;
  height: 313px;
  border-radius: 10px;
  margin: 0 13.33px 26px;
  padding: 14px 15px 0;
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
}
.floats {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.ranking_li_box {
  width: 100%;
  height: 198px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.ranking_li_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
}
.ranking_li_name {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 51px;
  letter-spacing: 0px;
  color: #333333;
}
.dailyWord_li_l {
  line-height: 36px;
  font-size: 14px;
  color: #999999;
}
.dailyWord_li_l img {
  width: 36px;
  height: 36px;
  background-color: #ffffff;
  border: solid 1px #f6f6f6;
  box-sizing: border-box;
  border-radius: 36px;
  margin-right: 8px;
}
.dailyWord_li_r {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 36px;
  letter-spacing: 0px;
  color: #999999;
}
.daily_li:hover .ranking_li_box img {
  transform: scale(1.08);
}
.ranking_li_info_one{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 51px;
}
.ranking_li_info_one_name {
  width: 180px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 0px;
  color: #928f92;
  overflow: hidden;
  /* 第二步：让文本不会换行， 在同一行继续 */
  white-space: nowrap;
  /* 第三步：用省略号来代表未显示完的文本 */
  text-overflow: ellipsis;
}
.ranking_li_upvote{
  display: flex;
  align-items: center;
  font-family: MiSans-Light;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 12px;
	letter-spacing: 1px;
	color: #928f92;
  cursor: pointer
}
.ranking_li_upvote.in,.ranking_li_info_one_name.in{
  color: #5857ff;
}
.ranking_li_upvote img{
  height: 12px;
  margin-right: 3px;
}
.show_hide {
  position: absolute;
  z-index: 99;
  left: 20px;
  top: 20px;
  width: 70px;
  height: 30px;
  transition: 0.3s;
  border-radius: 5px;
  text-align: center;
  align-items: center;
}
.show_hide >>> .el-input.is-focus .el-input__inner {
  border-color: none;
}
.show_hide >>> .el-input__inner {
  border: none;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    height: 25px;
    line-height: 25px;
    padding: 0 10px;
}
.show_hide >>> .el-select .el-input .el-select__caret{ color: #fff;}
.show_hide >>> .el-input__icon{height: 25px; line-height: 25px;}
/* .show_hide >>>.el-select-dropdown{background-color: rgba(0, 0, 0, 0.4);} 
.show_hide >>> .el-select-dropdown__item{ color: #fff;}
.show_hide >>> .el-select-dropdown__item.selected{ color: #fff;}
.show_hide >>> .el-select-dropdown__item.hover{ background-color: rgba(0, 0, 0, 0.4); color: #fff;} */
.deletes {
  width: 43px;
  height: 26px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 26px;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: 20px;
  right: 20px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.show_hide >>> .el-switch__label.is-active {
  color: #5957ff;
}
.show_hide >>> .el-switch__label span {
  font-size: 12px !important;
}
</style>

<style>
.userDaily .el-dialog{
  width: 1066px;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  margin: 0 !important;
}
.userDaily .el-dialog__header{
  display: none;
}
.userDaily .el-dialog__body{
  padding: 0;
}
</style>